<template>
    <div>
        <img src="@/assets/imgs/error_404.png"/>
        <h1>您访问的地址失效！点我返回首页~</h1>
    </div>
</template>

<script>
    export default {
        
    }
</script>

<style lang="less" scoped>

</style>